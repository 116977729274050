//
//
//
//

import pageMixin from '$mixins/page-mixin.js';
import StepLayout from '../cuota-personalizada/steps/step-layout.vue';
import { createExistingLead } from '$network/api.js';
import { fetchStepsData } from '../cuota-personalizada/lib/api.js';

export default {
  name: 'LeadFromAutomatic',
  components: {
    StepLayout
  },
  mixins: [pageMixin],
  data: () => ({}),
  async created() {
    const channel = 'automatic';

    const { leadId, uid } = this.$route.params;

    try {
      await fetchStepsData(uid);
    } catch (e) {
      await createExistingLead(channel, leadId, uid);
    }

    this.$router.push({
      name: 'Cuota personalizada',
      params: {
        uid
      }
    });
  }
};
